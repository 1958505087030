/* @font-face {
    font-family: 'Graphik';
    src: url('./fonts/Graphik-Light.woff2') format('woff2'),
        url('./fonts/Graphik-Light.woff') format('woff');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts/Graphik-LightItalic.woff2') format('woff2'),
        url('./fonts/Graphik-LightItalic.woff') format('woff');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts/Graphik-Regular.woff2') format('woff2'),
        url('./fonts/Graphik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts/Graphik-RegularItalic.woff2') format('woff2'),
        url('./fonts/Graphik-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts/Graphik-Semibold.woff2') format('woff2'),
        url('./fonts/Graphik-Semibold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
        url('./fonts/Graphik-SemiboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
} */

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-VariableFont.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-Italic-VariableFont.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}

@font-face {
    font-family: 'Mogra';
    src: url('./fonts/Mogra-Regular.ttf') format('truetype');
    font-weight: 100 900;
}

* {
    scroll-behavior: smooth;
}

/*scrollbar design CSS - start*/

/* ::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar {
    right: 0;
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 8px;
    width: 12px;
    height: 12px;
} */

/*scrollbar design CSS - end*/